import Box from "@mui/material/Box";
import { useTranslatedMessage } from "../../../hooks/use-translated-message";
import Template from "../Template";
import { useParams } from 'react-router-dom';
import AccountCard from "../../user-center/account/AccountCard";
import { LoadingPage } from "../../LoadingIndicator";
import { useFetchDictOrders } from "../../../hooks/use-fetch-dict-orders";
import OrderActions from "./OrderActions";

const CheckOrder = () => {
    const message = useTranslatedMessage();

    const params = useParams();

    const orderId = +(params?.orderId ?? 0);
    const orderNo = params?.orderNo ?? "";
    const userId = +(params?.userId ?? "");

    const getDecodedEmail = (hashedEmail: string) => {
        if (!hashedEmail) return "";
        let email = "";
        try {
            email = atob(hashedEmail);
        } catch { }

        return email;
    }

    const email = getDecodedEmail(params?.hashedEmail ?? "");

    // Skip querying an order if email is not found or orderId or orderNo is not provided
    const skip = !email || !orderId || !orderNo;

    const { orders, loading: ordersLoading } = useFetchDictOrders({ orderId, skip });
    const currOrder = orders.find(order => order.orderId === orderId);

    const isOrderFound = Boolean(currOrder)
        && currOrder?.userEmail === email
        && (currOrder?.userId === userId || userId === 0)
        && currOrder.orderNo === orderNo;

    return (
        <Template title={""} noBreadcrumb>
            {ordersLoading
                ? <LoadingPage />
                : <Box>
                    {!isOrderFound && <Box sx={{ mb: 1, mt: 2 }}>
                        {message('Order.CheckOrder.OrderNotFound')}
                    </Box>}
                    {isOrderFound &&
                        <AccountCard
                            key={currOrder.orderId}
                            order={currOrder}
                            readonly
                            showDetails
                            showUserInfo
                            asExternalOrder
                        />
                    }
                    {isOrderFound && <OrderActions order={currOrder} />}
                </Box>
            }
        </Template>
    )
}

export default CheckOrder;