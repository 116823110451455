import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslatedMessage } from "../../../hooks/use-translated-message";
import useNavigate from "../../../hooks/use-navigate";
import { LocalStorageKey } from "../../../constants";
import { getFromLocalStore } from "../../../utils/local-storage";
import { DictOrderFull } from "../../../types";
import { ReactNode } from "react";

interface OrderActionProps {
    order?: DictOrderFull
}

const Wrapper = ({ children }: { children: ReactNode }) => (
    <Stack direction={'row'} justifyContent={'center'}>
        {children}
    </Stack>
)

const OrderActions = ({ order }: OrderActionProps) => {
    const message = useTranslatedMessage();
    const { navigateToMain, navigateToAccount, navigateToRegister } = useNavigate();
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";

    if (!order) return null;

    if (order.userId === 0 && order.orderStateId === 2) {
        return (<Wrapper>
            <Button color="primary"
                sx={{ margin: '24px 18px' }}
                variant="contained"
                onClick={() => navigateToRegister({
                    email: order.userEmail,
                    orderNo: order.orderNo,
                    orderId: order.orderId,
                    isActivate: true
                })}>
                {message('Form.Auth.SignUpAndActivate')}
            </Button>
        </Wrapper>)
    }

    if (order.userId > 0 && order.orderStateId >= 3) {
        return (
            <Wrapper>
                <>
                    <Button color="primary"
                        sx={{ margin: '24px 18px' }}
                        variant="contained"
                        onClick={() => navigateToMain({ hardRefresh: true, industry: industryInCache })}
                    >
                        {message('Form.Button.ReturnHome')}
                    </Button>
                    <Button color="primary"
                        sx={{ margin: '24px 18px' }}
                        variant="contained"
                        onClick={() => navigateToAccount(true)}>
                        {message('Order.Button.GoToAccount')}
                    </Button>
                </>
            </Wrapper>
        );
    }
}

export default OrderActions;