export enum LocaleKey {
    'en' = 'en',
    'zh' = 'zh'
}

export type LocaleKeyType = keyof typeof LocaleKey;

export type TranslationItem = {
    [key in LocaleKey]: string;
}

export const translations: { [key: string]: TranslationItem } = {
    'General.Address': {
        'en': 'Address',
        'zh': '地址'
    },
    'General.DictName': {
        'en': 'SCIdict',
        'zh': 'SCIdict赛斯专业词典'
    },
    'General.Email': {
        'en': 'Email',
        'zh': '邮箱'
    },
    'General.Placeholder': {
        'en': 'Empty',
        'zh': '没有信息'
    },
    'General.LogoText': {
        'en': ' ',
        'zh': '赛斯专业词典'
    },
    'General.LogoTextSup': {
        'en': ' ',
        'zh': '赛斯'
    },
    'General.LogoAltText': {
        'en': 'SCIdict',
        'zh': 'SCIdict赛斯专业词典'
    },
    'General.No': {
        'en': 'No',
        'zh': '否'
    },
    'General.Yes': {
        'en': 'Yes',
        'zh': '是'
    },
    'General.Off': {
        'en': 'Off',
        'zh': '关'
    },
    'General.On': {
        'en': 'On',
        'zh': '开'
    },
    'General.CopyRight.Year': {
        'en': 'Copyright © 2024',
        'zh': '版权所有 © 2024'
    },
    'General.CopyRight.Rights': {
        'en': 'All Rights Reserved',
        'zh': '保留所有权力'
    },
    'Navigation.AboutUs': {
        'en': 'About Us',
        'zh': '关于我们'
    },
    'Navigation.Account': {
        'en': 'Account',
        'zh': '我的账户'
    },
    'Navigation.BackHome': {
        'en': 'Go Back to SCIdict Home',
        'zh': '回到SCIdict赛斯专业词典首页'
    },
    'Navigation.CloseSidebar': {
        'en': 'Close Sidebar',
        'zh': '关闭侧边栏'
    },
    'Navigation.ContactUs': {
        'en': 'Contact Us',
        'zh': '联系我们'
    },
    'Navigation.FavorateWords': {
        'en': 'Favorate Entries',
        'zh': '收藏的词条'
    },
    'Navigation.Glossary': {
        'en': 'Glossary',
        'zh': '我的词汇'
    },
    'Navigation.Home': {
        'en': 'Home',
        'zh': '主页'
    },
    'Navigation.Language': {
        'en': 'Language',
        'zh': '语言'
    },
    'Navigation.Logout': {
        'en': 'Sign Out',
        'zh': '退出'
    },
    'Navigation.PrivacyPolicy': {
        'en': 'Privacy Policy',
        'zh': '隐私政策'
    },
    'Navigation.Product': {
        'en': 'Product',
        'zh': '产品'
    },
    'Navigation.Profile': {
        'en': 'Profile',
        'zh': '个人资料'
    },
    'Navigation.SearchHistory': {
        'en': 'Search History',
        'zh': '搜索历史'
    },
    'Navigation.Settings': {
        'en': 'Settings',
        'zh': '设置'
    },
    'Navigation.TermsAndConditions': {
        'en': 'Temrs and Conditions',
        'zh': '服务条款与条件'
    },
    'Navigation.UserCenter': {
        'en': 'User Center',
        'zh': '用户中心'
    },
    'Form.Auth.ForgotPassword': {
        'en': 'Forgot Password',
        'zh': '忘记密码'
    },
    'Form.Auth.ResetPassword': {
        'en': 'Reset Password',
        'zh': '重置密码'
    },
    'Form.Auth.RecoverPassword': {
        'en': 'Recover Password',
        'zh': '找回密码'
    },
    'Form.Auth.SignIn': {
        'en': 'Sign In',
        'zh': '登录'
    },
    'Form.Auth.SignUp': {
        'en': 'Sign Up',
        'zh': '注册'
    },
    'Form.Auth.SignUpAndActivate': {
        'en': 'Sign Up & Activate',
        'zh': '注册并激活'
    },
    'Form.Button.Edit': {
        'en': 'Edit',
        'zh': '编辑'
    },
    'Form.Button.Cancel': {
        'en': 'Cancel',
        'zh': '取消'
    },
    'Form.Button.Confirm': {
        'en': 'Confirm',
        'zh': '确定'
    },
    'Form.Button.ConfirmChangePassword': {
        'en': 'Confirm Password Change',
        'zh': '确认修改密码'
    },
    'Form.Button.Delete': {
        'en': 'Delte',
        'zh': '删除'
    },
    'Form.Button.OK': {
        'en': 'OK',
        'zh': '确定'
    },
    'Form.Button.Save': {
        'en': 'Save',
        'zh': '保存'
    },
    'Form.Button.ReturnHome': {
        'en': 'Return Home',
        'zh': '回到主页'
    },
    'Form.Button.ViewPassword': {
        'en': 'View Password',
        'zh': '查看密码'
    },
    'Form.PlaceHolder.PleaseEnterSearch':
    {
        'en': 'Please Enter Search Words',
        'zh': '请输入搜索词'
    },
    'Form.PlaceHolder.Search':
    {
        'en': 'Search...',
        'zh': '搜索...'
    },
    'Form.Msg.CantQueryWord': {
        'en': `Can't query the input words`,
        'zh': '无法查询您输入的词'
    },
    'Form.Msg.CurrentUserNonExist': {
        'en': 'Cannot update because the current user does not exist.',
        'zh': '当前用户不存在，无法更新'
    },
    'Form.Msg.EmptyField': {
        'en': ' is empty',
        'zh': '为空'
    },
    'Form.Msg.FieldIsEmpty': {
        'en': '{field} should not be empty',
        'zh': '{field}不应为空'
    },
    'Form.Msg.FailureAccountActivation': {
        'en': 'Failed to activate account',
        'zh': '账户激活失败'
    },
    'Form.Msg.FailureAuth': {
        'en': 'Please sign in',
        'zh': '请登录'
    },
    'Form.Msg.FailureAuthForPro': {
        'en': 'Please sign in to use SCIDICT PRO',
        'zh': '请注册或登录后使用SCIDICT专业版'
    },
    'Form.Msg.FailureCancelSubscription': {
        'en': 'Failed to cancel your subscription. Please contact us at support@scidict.org.',
        'zh': '取消订阅失败，请联系我们 support@scidict.org'
    },
    'Form.Msg.FailureCopy': {
        'en': 'Failed to copy',
        'zh': '拷贝失败'
    },
    'Form.Msg.FailureDeletePaymentMethod': {
        'en': 'Failed to delete selected payment methods.',
        'zh': '删除支付方式失败'
    },
    'Form.Msg.FailureRecoverPass': {
        'en': 'Failed to recover password',
        'zh': '密码找回失败'
    },
    'Form.Msg.FailureResetPass': {
        'en': 'Failed to reset password',
        'zh': '密码重置失败'
    },
    'Form.Msg.FailureSetDefaultPaymentMethod': {
        'en': 'Failed to set a default payment method.',
        'zh': '默认支付方式设置失败'
    },
    'Form.Msg.FailureSignUp': {
        'en': 'Failed to sign up',
        'zh': '注册失败'
    },
    'Form.Msg.FailureSignIn': {
        'en': 'Failed to sign in',
        'zh': '登录失败'
    },
    'Form.Msg.FailureUpdateDictTypes': {
        'en': 'Failed to update dictionary',
        'zh': '更新词典失败'
    },
    'Form.Msg.FailureUpdateProfile': {
        'en': 'Failed to update profile',
        'zh': '更新个人信息失败'
    },
    'Form.Msg.HighlightWord': {
        'en': 'Highlight Keywords',
        'zh': '突出显示关键词'
    },
    'Form.Msg.InconsistentPasswords': {
        'en': 'Inconsistent passwords input',
        'zh': '两次密码输入不一致'
    },
    'Form.Msg.IncorrectFormat': {
        'en': ' has incorrect Format',
        'zh': '格式不正确'
    },
    'Form.Msg.NoPersonalInfoToEdit': {
        'en': 'No fields in your profile have been changed!',
        'zh': '没有可更改的个人信息!'
    },
    'Form.Msg.NoReturn': {
        'en': 'No matched phrase pairs returned',
        'zh': '返回 0 条匹配短语对'
    },
    'Form.Msg.NotANumber': {
        'en': 'Not a number',
        'zh': '不是数字'
    },
    'Form.Msg.NotAValidEmail': {
        'en': ' not valid',
        'zh': '不规范'
    },
    'Form.Msg.InvalidResetLink': {
        'en': 'Invalid password reset link',
        'zh': '密码重置链接不合法'
    },
    'Form.Msg.Password.AtLeast8Chars': {
        'en': ' should have at least 8 characters',
        'zh': '应至少为8个字符'
    },
    'Form.Msg.Password.AtLeastOneUpperCase': {
        'en': ' should contain at least one upper case letter',
        'zh': '应至少包含一个大写字母'
    },
    'Form.Msg.Password.AtLeastOneLowerCase': {
        'en': ' should contain at least one lower case letter',
        'zh': '应至少包含一个小写字母'
    },
    'Form.Msg.Password.AtLeastOneNumber': {
        'en': ' should contain at least one number',
        'zh': '应至少包含一个数字'
    },
    'Form.Msg.Password.AtLeastOneSpecialChar': {
        'en': ' should contain at least one special charater',
        'zh': '应至少包含一个特殊字符'
    },
    'Form.Msg.Password.AtMost20Chars': {
        'en': ' should be at most 20 characters',
        'zh': '应至多为20个字符'
    },
    'Form.Msg.PlsChangeForgotPassInfo': {
        'en': 'Please make changes to your forgot password form based on hint',
        'zh': '请根据提示修改忘记密码信息'
    },
    'Form.Msg.PlsChangeOrderInfo': {
        'en': 'Please make changes to your order form based on hint',
        'zh': '请根据提示修改订单信息'
    },
    'Form.Msg.PlsChangePersonalInfo': {
        'en': 'Please make changes to your profile based on hint',
        'zh': '请根据提示修改个人信息'
    },
    'Form.Msg.PlsChangeSignInInfo': {
        'en': 'Please make changes to your sign in information based on hint',
        'zh': '请根据提示修改登录信息'
    },
    'Form.Msg.PlsChangeSignUpInfo': {
        'en': 'Please make changes to your sign up information based on hint',
        'zh': '请根据提示修改注册信息'
    },
    'Form.Msg.PlsCheckUserAgreement': {
        'en': 'Please read and check if you agree with the user agreement',
        'zh': '请阅读并勾选同意用户注册协议'
    },
    'Form.Msg.PlsValidateHuman': {
        'en': 'Please verify the reCAPTCHA!',
        'zh': '请验证reCAPTCHA'
    },
    'Form.Msg.SetupSuccess': {
        'en': 'Setup Success',
        'zh': '设置成功'
    },
    'Form.Msg.SuccessCancelSubscription': {
        'en': 'You have successfully canceled auto-renew!',
        'zh': '您已成功取消自动续费!'
    },
    'Form.Msg.SuccessChangePersonalInfo': {
        'en': 'You have successfully updated your profile!',
        'zh': '您已成功更新个人信息!'
    },
    'Form.Msg.SuccessDeletePaymentMethod': {
        'en': 'You have successfully deleted the selected payment methods.',
        'zh': '删除支付方式成功'
    },
    'Form.Msg.SuccessLogout': {
        'en': 'You have successfully logged out. See you again!',
        'zh': '您已成功退出，欢迎回来'
    },
    'Form.Msg.SuccessResetPassword': {
        'en': 'You have successfully reset your password. Jumping to the Sign In',
        'zh': '密码重置成功，即将跳转到登录页面'
    },
    'Form.Msg.SuccessSetDefaultPaymentMethod': {
        'en': 'You have successfully set a default payment method.',
        'zh': '默认支付方式设置成功'
    },
    'Form.Msg.SuccessUpdateDictTypes': {
        'en': 'You have successfully updated dictionary',
        'zh': '更新词典成功'
    },
    'Form.Msg.OrderFailure': {
        'en': 'You failed to submit the order. Please contact us at support@scidict.org',
        'zh': '您提交订单失败。请联系我们：support@scidict.org'
    },
    'Form.Msg.OrderSuccess': {
        'en': 'You have successfully placed an order [{orderno}]. Thank you!',
        'zh': '您成功提交了订单 [{orderno}]，谢谢！'
    },
    'Form.Msg.TooLong': {
        'en': ' too long',
        'zh': '过长'
    },
    'Form.Msg.TooShort': {
        'en': ' too short',
        'zh': '过短'
    },
    'Form.Msg.XReturn': {
        'en': '{total} matched phrase pairs returned',
        'zh': '返回 {total} 条匹配短语对'
    },
    'Form.Msg.WelcomeSignIn': {
        'en': 'Hi {name}, Welcome to Scidict!',
        'zh': '欢迎您 {name}，登录成功！'
    },
    'Form.Msg.WelcomeSignUp': {
        'en': 'Greetings, {name}! You have successfully signed up to Scidict!',
        'zh': '欢迎您 {name}，您已注册成功！'
    },
    'Form.Msg.WordTooLong': {
        'en': 'Search words are too long',
        'zh': '搜索词过长'
    },
    'Form.Field.ConfirmNewPassword': {
        'en': 'Confirm New Password',
        'zh': '确认新密码'
    },
    'Form.Field.NewPassword': {
        'en': 'New Password',
        'zh': '新密码'
    },
    'Form.Field.Password': {
        'en': 'Password',
        'zh': '密码'
    },
    'Form.Field.PleaseConfirmNewPassword': {
        'en': 'Please Confirm New Password',
        'zh': '请确认新密码'
    },
    'Form.Field.PleaseEnterCurrentPassword': {
        'en': 'Please Enter Current Password',
        'zh': '请输入当前密码'
    },
    'Form.Field.PleaseEnterNewPassword': {
        'en': 'Please Enter New Password',
        'zh': '请输入新密码'
    },
    'Form.Field.RememberMyChoice': {
        'en': 'Remember my choice',
        'zh': '记住我的选择'
    },
    'Form.Field.SelectAll': {
        'en': 'Select all',
        'zh': '选择所有'
    },
    'Form.Field.SelectedRowNumber': {
        'en': '{num} rows selected',
        'zh': '已选择{num}个'
    },
    'Form.Field.SortedDescendingly': {
        'en': 'sorted descendingly',
        'zh': '倒序排列'
    },
    'Form.Field.SortedAscendingly': {
        'en': 'sorted ascendingly',
        'zh': '顺序排列'
    },
    'Form.Field.ReadAndAgree': {
        'en': `Read and check if you agree with the `,
        'zh': `阅读并同意`
    },
    'Form.Field.UserAgreement': {
        'en': 'user agreement',
        'zh': '用户注册协议'
    },
    'Form.Field.UserSignUp': {
        'en': 'Sign Up',
        'zh': '用户注册'
    },
    'Form.Profile.Title': {
        'en': 'Profile',
        'zh': '个人信息'
    },
    'Form.Profile.Name': {
        'en': 'Name',
        'zh': '姓名'
    },
    'Form.Profile.Nickname': {
        'en': 'Nick Name',
        'zh': '昵称'
    },
    'Form.Profile.Email': {
        'en': 'Email',
        'zh': '邮箱地址'
    },
    'Form.Profile.Mobile': {
        'en': 'Mobile',
        'zh': '手机号'
    },
    'Form.Profile.Phone': {
        'en': 'Phone',
        'zh': '电话'
    },
    'Form.Profile.Country': {
        'en': 'Country & Area',
        'zh': '国家地区'
    },
    'Form.Profile.State': {
        'en': 'State',
        'zh': '省/州'
    },
    'Form.Profile.City': {
        'en': 'City',
        'zh': '城市'
    },
    'Form.Profile.District': {
        'en': 'District',
        'zh': '区'
    },
    'Form.Profile.StreetAddress': {
        'en': 'Street Address',
        'zh': '街道地址'
    },
    'Form.Profile.ZipCode': {
        'en': 'Postal Code',
        'zh': '邮政编码'
    },
    'Form.Profile.Institution': {
        'en': 'Organization',
        'zh': '单位'
    },
    'Form.Profile.Profession': {
        'en': 'Profession',
        'zh': '职业'
    },
    'Form.Profile.Industry': {
        'en': 'Industry',
        'zh': '行业'
    },
    'Form.Profile.Specialization': {
        'en': 'Specialization',
        'zh': '专业'
    },
    'Form.Account.ActiveFrom': {
        'en': 'Active From',
        'zh': '激活时间'
    },
    'Form.Account.AutoRenew': {
        'en': 'Automatic Renew',
        'zh': '自动续费'
    },
    'Form.Account.ChangePassword': {
        'en': 'Change Password',
        'zh': '修改密码'
    },
    'Form.Account.CancelSubscription': {
        'en': 'Cancel auto-renew',
        'zh': '取消自动续费'
    },
    'Form.Account.ConfirmCancelSubscriptionDialogContent': {
        'en': 'Please confirm if you want to cancel auto-renew',
        'zh': '请确认您是否想要取消自动续费'
    },
    'Form.Account.ConfirmCancelSubscriptionDialogTitle': {
        'en': 'Confirm canceling auto-renew',
        'zh': '确认取消自动续费'
    },
    'Form.Account.ChangeDictionary': {
        'en': 'Change Dictionary',
        'zh': '更改词典'
    },
    'Form.Account.ChangeDictionaryAbbr': {
        'en': 'Change',
        'zh': '更改词典'
    },
    'Form.Account.ChangeSubscription': {
        'en': 'Change Subscription',
        'zh': '更改订阅'
    },
    'Form.Account.EditAccountButtonGroupAriaLabel': {
        'en': 'Edit Subscription Button Group',
        'zh': '修改订阅按钮组'
    },
    'Form.Account.Expiry': {
        'en': 'Validity Period',
        'zh': '有效期限'
    },
    'Form.Account.NextAutoPayDate': {
        'en': 'Auto-Pay Date',
        'zh': '自动续费日期'
    },
    'Form.Account.NextAutoPayAmount': {
        'en': 'Auto-Pay Amount',
        'zh': '自动续费价格'
    },
    'Form.Account.Level.BasicAccount': {
        'en': 'Basic Account',
        'zh': '基础版'
    },
    'Form.Account.Level.UniProAccount': {
        'en': 'UniPro',
        'zh': '专业版I'
    },
    'Form.Account.Level.BiProAccount': {
        'en': 'BiPro',
        'zh': '专业版II'
    },
    'Form.Account.Level.TriProAccount': {
        'en': 'TriPro',
        'zh': '专业版III'
    },
    'Form.Account.Level.VipProAccount': {
        'en': 'ExpPro',
        'zh': '专业版VIP'
    },
    'Form.Account.MoreActionsAriaLabel': {
        'en': 'More Actions',
        'zh': '更多操作'
    },
    'Form.Account.MoreProduct': {
        'en': 'More Product',
        'zh': '更多产品'
    },
    'Form.Account.MoreProductAbbr': {
        'en': 'More',
        'zh': '更多产品'
    },
    'Form.Account.MyBalance': {
        'en': 'My Balance',
        'zh': '我的余额'
    },
    'Form.Account.OrderHistory': {
        'en': 'Order History',
        'zh': '订单历史'
    },
    'Form.Account.PaymentMethod': {
        'en': 'Payment Method',
        'zh': '付款方式'
    },
    'Form.Account.PaymentMethod.Default': {
        'en': 'Default',
        'zh': '默认'
    },
    'Form.Account.PaymentMethod.SetAsDefault': {
        'en': 'Set as default',
        'zh': '设为默认'
    },
    'Form.Account.ProAccount': {
        'en': 'Pro Account',
        'zh': '专业版'
    },
    'Form.Account.Purchase': {
        'en': 'Purchase',
        'zh': '购买'
    },
    'Form.Account.RenewSubscription': {
        'en': 'Renew',
        'zh': '续订'
    },
    'Form.Account.RenewDialogContent': {
        'en': 'Renew {product} for {duration} at {price}?',
        'zh': '续订{product}{duration}并支付{price}？'
    },
    'Form.Account.SubscribedDict': {
        'en': 'Selected Dictionaries',
        'zh': '所选词典'
    },
    'Form.Account.Subscription': {
        'en': 'Subscription',
        'zh': '订阅信息'
    },
    'Form.Account.Subscription.Duration': {
        'en': 'Subscription Duration',
        'zh': '订阅期限'
    },
    'Form.Account.Subscription.OneMonth': {
        'en': '1 Month',
        'zh': '一个月'
    },
    'Form.Account.Subscription.OneYear': {
        'en': '1 Year',
        'zh': '一年'
    },
    'Form.Account.Subscription.SixMonths': {
        'en': '6 Months',
        'zh': '六个月'
    },
    'Form.Account.Subscription.ThreeMonths': {
        'en': '3 Months',
        'zh': '三个月'
    },
    'Form.Account.SubscriptionType': {
        'en': 'Subscription Type',
        'zh': '订阅类型'
    },
    'Form.Account.Trial': {
        'en': 'Trial',
        'zh': '试用'
    },
    'Form.Account.TurnOnAutoRenew': {
        'en': 'Turn on Auto Renew',
        'zh': '启动自动续费'
    },
    'Form.Account.UpgradeToPro': {
        'en': 'Upgrade to Pro Account',
        'zh': '升级为专业版'
    },
    'Form.SourceWord': {
        'en': 'Source Word',
        'zh': '原文词'
    },
    'Form.TargetWord': {
        'en': 'Target Word',
        'zh': '译词'
    },
    'Form.SearchWord': {
        'en': 'Search Word',
        'zh': '搜索词'
    },
    'Form.SearchNumber': {
        'en': 'Number of Search',
        'zh': '搜索次数'
    },
    'Form.AccessTime': {
        'en': 'Access Time',
        'zh': '访问时间'
    },
    'Order.Button.ConfirmPurchase': {
        'en': 'Confirm Purchase',
        'zh': '确认购买'
    },
    'Order.Button.GoToAccount': {
        'en': 'Go to My Account',
        'zh': '我的账户'
    },
    'Order.BasicInfo': {
        'en': 'Basic Information',
        'zh': '基本信息'
    },
    'Order.Checkout.ConfirmRenewDialogContent': {
        'en': 'We have detected you have the following dictionaries active. If you want to renew your subscription, please click Confirm and submit order again.',
        'zh': '我们检测到您已有以下词典在使用中。如需更新使用期限请点击确认，然后再次提交订单'
    },
    'Order.Checkout.ConfirmRenewDialogTitle': {
        'en': 'Confirm subscription renewal',
        'zh': '确认更新订阅'
    },
    'Order.Checkout.PaymentCanceled': {
        'en': 'Payment Canceled',
        'zh': '支付已取消'
    },
    'Order.Checkout.PaymentFailure': {
        'en': 'Payment Failed',
        'zh': '支付失败'
    },
    'Order.Checkout.PaymentFailureContent': {
        'en': 'Payment failed, please try again',
        'zh': '支付失败，请重试!'
    },
    'Order.Checkout.PaymentMethodError': {
        'en': 'Error confirming payment method',
        'zh': '支付方式出错'
    },
    'Order.Checkout.PaymentProcessing': {
        'en': 'Payment Processing',
        'zh': '支付进行中'
    },
    'Order.Checkout.PaymentRequiresAction': {
        'en': 'Payment Requires Action',
        'zh': '请继续完成支付'
    },
    'Order.Checkout.PaymentRequiresCapture': {
        'en': 'Payment Confirmation Pending',
        'zh': '待确认支付'
    },
    'Order.Checkout.PaymentRequiresPaymentMethod': {
        'en': 'Please continue to complete payment',
        'zh': '请继续完成支付'
    },
    'Order.Checkout.PaymentSuccess': {
        'en': 'Payment Success',
        'zh': '支付成功'
    },
    'Order.Checkout.PaymentSuccessContent': {
        'en': 'You have successfully paid {amount}!',
        'zh': '您已成功支付了 {amount}!'
    },
    'Order.Checkout.SetupAutoRenewSuccessContent': {
        'en': 'You have successfully setup auto renew of your subscription!',
        'zh': '您已成功设置自动续费'
    },
    'Order.CheckOrder.OrderNotFound': {
        'en': 'Order not found or your link invalid',
        'zh': '没有查询到您的订单或者您的链接地址有误'
    },
    'Order.ContactInfo': {
        'en': 'Contact Information',
        'zh': '联系信息'
    },
    'Order.ContactInfoTooltip': {
        'en': 'We are required to calculate applicable tax for your location. We also issue electronic invoice of your order based on the contact information provided.',
        'zh': '根据规定，我们需要通过您的地址来计算您所在地区的税率。我们根据您提供的联系信息为您开具电子发票。'
    },
    'Order.CreateTime': {
        'en': 'Submitted',
        'zh': '提交时间'
    },
    'Order.OrderNo': {
        'en': 'Order Number',
        'zh': '订单号'
    },
    'Order.AmountPaid': {
        'en': 'Amount Paid',
        'zh': '已付金额'
    },
    'Order.OrderStatus': {
        'en': 'Order Status',
        'zh': '订单状态'
    },
    'Order.OrderStatus.Submitted': {
        'en': 'Pending Payment',
        'zh': '待付款'
    },
    'Order.OrderStatus.Paid': {
        'en': 'Paid',
        'zh': '已付款'
    },
    'Order.OrderStatus.Activated': {
        'en': 'Activated',
        'zh': '已激活'
    },
    'Order.OrderStatus.Expired': {
        'en': 'Expired',
        'zh': '已到期'
    },
    'Order.OrderStatus.Canceled': {
        'en': 'Canceled',
        'zh': '已取消'
    },
    'Order.Pay': {
        'en': 'Pay',
        'zh': '支付'
    },
    'Order.PayNow': {
        'en': 'Pay Now',
        'zh': '在线支付'
    },
    'Order.PaymentInfo': {
        'en': 'Payment Information',
        'zh': '付款信息'
    },
    'Order.Price.BasePrice': {
        'en': 'Base Price',
        'zh': '基础价格'
    },
    'Order.Price.TaxCharge': {
        'en': 'Tax Charge',
        'zh': '税'
    },
    'Order.Price.TotalPrice': {
        'en': 'Total Price',
        'zh': '总计'
    },
    'Order.SelectedDictTypes': {
        'en': 'Pro Dictionaries Selected',
        'zh': '所选词典专业'
    },
    'Order.SubscriptionType': {
        'en': 'Subscription',
        'zh': '订阅类型'
    },
    'Order.ValidPeriod': {
        'en': 'Validlity Period',
        'zh': '有效期'
    },
    'Product.ComparisonTitle': {
        'en': 'Feature Comparison: Basic vs Pro Account',
        'zh': '基本和专业账户功能对比'
    },
    'Product.Checkout': {
        'en': 'Checkout',
        'zh': '订单提交'
    },
    'Product.ProDict': {
        'en': 'SCIDICT Pro',
        'zh': 'SCIDICT专业版'
    },
    'Product.ProductDes.AllIncludedInBasic': {
        'en': 'All included in basic account',
        'zh': '所有基本版包括的功能'
    },
    'Product.ProductDes.Basic.Line1': {
        'en': 'Personal search history management/retrieval (with opt-out support)',
        'zh': '搜索记录管理和检索(可关闭)'
    },
    'Product.ProductDes.Basic.Line2': {
        'en': 'Customizable settings',
        'zh': '自定义设置'
    },
    'Product.ProductDes.Basic.Line3': {
        'en': 'Dictionary search in All (general category)',
        'zh': '在"综合"类别下进行词典搜索'
    },
    'Product.ProductDes.BiPro.Line1': {
        'en': 'Search in dictionary of two additional specialized fields',
        'zh': '支持从两个额外的专业领域进行词典搜索'
    },
    'Product.ProductDes.BiPro.Line2': {
        'en': 'Switch specialized field once every 15 days',
        'zh': '每15天可切换专业领域一次'
    },
    'Product.ProductDes.Pro.Line1': {
        'en': 'Support search in specialized fields',
        'zh': '支持在专业领域内进行词典搜索'
    },
    'Product.ProductDes.Pro.Line2': {
        'en': 'Support switch of specialized fields',
        'zh': '支持切换专业领域'
    },
    'Product.ProductDes.Pro.Line3': {
        'en': 'Support adding glossary to favorite',
        'zh': '支持将词汇加入收藏'
    },
    'Product.ProductDes.Pro.Line4': {
        'en': 'Smart glossary organization based on search history & favorites',
        'zh': '基于搜索历史和收藏的智能词汇整理'
    },
    'Product.ProductDes.Pro.Line5': {
        'en': 'Enhanced customizable search settings',
        'zh': '增强的自定义搜索设置'
    },
    'Product.ProductDes.Pro.Line6': {
        'en': 'Enhanced target word matching',
        'zh': '增强的译词匹配效果'
    },
    'Product.ProductDes.Pro.Line7': {
        'en': 'Drill-down from search result',
        'zh': '从搜索结果中进一步搜索'
    },
    'Product.ProductDes.TriPro.Line1': {
        'en': 'Search in dictionary of three additional specialized fields',
        'zh': '支持从三个额外的专业领域进行词典搜索'
    },
    'Product.ProductDes.TriPro.Line2': {
        'en': 'Switch specialized field once every 7 days',
        'zh': '每7天可更新专业领域一次'
    },
    'Product.ProductDes.UniPro.Line1': {
        'en': 'Search in dictionary of one additional specialized field',
        'zh': '支持从一个额外的专业领域进行词典搜索'
    },
    'Product.ProductDes.UniPro.Line2': {
        'en': 'Switch specialized field once every 30 days',
        'zh': '每30天可切换专业领域一次'
    },
    'Product.ProductDes.VipPro.Line1': {
        'en': 'Search in dictionary of nine additional specialized fields',
        'zh': '支持从九个额外的专业领域进行词典搜索'
    },
    'Product.ProductDes.VipPro.Line2': {
        'en': 'No need to switch specialized fields, all available at any time',
        'zh': '无需切换专业领域，可随时在九个领域内搜索'
    },
    'Product.Specialty': {
        'en': 'Specialties',
        'zh': '专业领域'
    },
    'Product.SelectDictTypes': {
        'en': 'Please select {num} specialized fields for your {subscription} subscription',
        'zh': '{subscription}：请选择{num}个专业词典领域'
    },
    'Settings.EntryState': {
        'en': 'Expanded/Folded Entries Display',
        'zh': '词条扩展/紧缩显示'
    },
    'Settings.Expand': {
        'en': 'Expand',
        'zh': '扩展'
    },
    'Settings.Fold': {
        'en': 'Fold',
        'zh': '缩小'
    },
    'Settings.KeywordColor': {
        'en': 'Keyword Color',
        'zh': '关键词颜色'
    },
    'Settings.SidebarState': {
        'en': 'Expanded/Folded Sidebar',
        'zh': '侧边栏扩展/缩小'
    },
    'Settings.ExpandTarget': {
        'en': 'Show Only Focused Target Words',
        'zh': '默认仅显示重点译词'
    },
    'Settings.ShowAllTarget': {
        'en': 'Show unmatched target words',
        'zh': '显示未匹配的译词'
    },
    'Settings.HideWeakTarget': {
        'en': 'Hide weakly matched target words',
        'zh': '隐藏弱匹配的译词'
    },
    'Settings.ReturnEntryCount': {
        'en': 'Number of Entried Returned (Pro)',
        'zh': '返回词条数量（专业版）'
    },
    'Specialty.FullName.Science': {
        'en': 'Science Dictionary',
        'zh': '科技专业词典'
    },
    'Specialty.FullName.BioMed': {
        'en': 'Dictionary of Biomedicine',
        'zh': '生物医药专业词典'
    },
    'Specialty.FullName.GeoSci': {
        'en': 'Geology and Earth Science Dictionary',
        'zh': '地球科学专业词典'
    },
    'Specialty.FullName.Mechanics': {
        'en': 'Dictionary of Mechanical Engineering',
        'zh': '机械专业词典'
    },
    'Specialty.FullName.Chemistry': {
        'en': 'Dictionary of Chemistry',
        'zh': '石油化工专业词典'
    },
    'Specialty.FullName.InfoTech': {
        'en': 'Dictionary of Information Technology',
        'zh': '电子信息专业词典'
    },
    'Specialty.FullName.Business': {
        'en': 'Dictionary of Business and Economics',
        'zh': '商业经济专业词典'
    },
    'Specialty.FullName.Humanity': {
        'en': 'Dictionary of Humanities',
        'zh': '人文科学专业词典'
    },
    'Specialty.Label.Science': {
        'en': 'Science',
        'zh': '科技'
    },
    'Specialty.Label.BioMed': {
        'en': 'BioMed',
        'zh': '生物医药'
    },
    'Specialty.Label.GeoSci': {
        'en': 'GeoSci',
        'zh': '地球科学'
    },
    'Specialty.Label.Mechanics': {
        'en': 'Mechanics',
        'zh': '机械'
    },
    'Specialty.Label.Chemistry': {
        'en': 'Chemistry',
        'zh': '石油化工'
    },
    'Specialty.Label.InfoTech': {
        'en': 'InfoTech',
        'zh': '电子信息'
    },
    'Specialty.Label.Business': {
        'en': 'Business',
        'zh': '商业经济'
    },
    'Specialty.Label.Humanity': {
        'en': 'Humanity',
        'zh': '人文科学'
    },
    'Specialty.Label.All': {
        'en': 'Synthetic',
        'zh': '综合'
    },
    'Search.AvailableSpecialties': {
        'en': 'Available Search Specialties',
        'zh': '搜索专业领域'
    },
    'Search.SearchInSpecialty': {
        'en': 'Search "{word}" in {specialty}',
        'zh': '在{specialty}中搜索"{word}"'
    },
    'Search.TooManyGuestRequests': {
        'en': 'Too many guest requests. Please register a free account to continue use. If you have an account already, please sign in.',
        'zh': '未登录查询次数过多，您可注册为免费用户继续使用，如已注册请登录'
    },
    'Search.TooManyRequests': {
        'en': 'Too many requests. You need to purchase SCIDICT Pro to unlock more resources',
        'zh': '查询次数过多，您需要购买专业版以解锁更多资源'
    },
    'Search.DictTypeNoAccessInstruction.Pro': {
        'en': 'You currently do not have access to search in {dictname}. To get access, you may either update your dictionaries if available in your Account or purchase more.',
        'zh': '您当前没有权限搜索{dictname}专业，您可在您的账户中更新您的词典专业或购买更多专业。'
    },
    'Search.DictTypeNoAccessInstruction.Guest': {
        'en': 'You currently do not have access to search in {dictname}. To get access, you need to purchase a pro account. If you have already purchased, please login.',
        'zh': '您当前没有权限搜索{dictname}专业，您需要购买专业账户。如已购买，请登录您的帐户'
    },
    'Search.RecentUpdate': {
        'en': 'Recent Update',
        'zh': '最近更新'
    },
    'SEO.ChineseEquivalent': {
        'en': 'Chinese equivalent',
        'zh': '汉语译词'
    },
    'SEO.ChineseTranslation': {
        'en': 'Chinese translation',
        'zh': '中文翻译'
    },
    'SEO.EnglishEquivalent': {
        'en': 'English equivalent',
        'zh': '英语译词'
    },
    'SEO.EnglishTranslation': {
        'en': 'English translation',
        'zh': '英文翻译'
    }
}

export type TranslationMessages = { [key: string]: string };

export const isLocaleKey = (key: any): key is LocaleKey => {
    return Object.keys(LocaleKey).includes(key);
}

export const getMessages = (locale: string): TranslationMessages => {
    if (!isLocaleKey(locale)) {
        locale = DEFAULT_LOCALE;
        console.log(`locale ${locale} is not found, falling back to ${DEFAULT_LOCALE}`);
    }
    const entries = Object.entries(translations);
    const message = entries.reduce(
        (acc, curr: ([string, TranslationItem])) => {
            const key = curr[0];
            const item = curr[1];
            acc[key] = item[locale as LocaleKeyType];
            return acc;
        },
        {} as TranslationMessages
    );
    return message;
};

export const DEFAULT_LOCALE = 'zh' as LocaleKeyType;