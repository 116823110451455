import React, { useState, MouseEvent, KeyboardEvent } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { LocalStorageKey, Size, USER_MENU_ITEMS } from '../../constants';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { isSearchResultPath } from '../../utils/url-path';
import DrawerMenuItem from './DrawerMenuItem';
import MainView from '../MainView';
import ProgressiveAppBar from '../navbar/ResponsiveAppBar';
import LogoButton from '../../LogoButton';
import LogoBanner from '../../LogoBanner';
import { MenuItemObj } from '../../types';
import useNavigate from '../../hooks/use-navigate';
import { useUserAPI } from '../../hooks/use-user-api';
import { getFromLocalStore } from '../../utils/local-storage';

const openedMixin = (theme: Theme): CSSObject => ({
    width: Size.DRAWER_WIDTH_EXPANDED,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${Size.DRAWER_WIDTH_SHRINKED}px + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${Size.DRAWER_WIDTH_SHRINKED} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: Size.DRAWER_WIDTH_SHRINKED
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: Size.DRAWER_WIDTH_EXPANDED,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface ContentTemplateProps {
    drawerOpen?: boolean;
    isOnSearchPage?: boolean;
}

const ContentTemplate = styled('div', { shouldForwardProp: (prop) => prop !== 'drawerOpen' && prop !== 'isOnSearchPage' })<ContentTemplateProps>(
    ({ drawerOpen, isOnSearchPage }) => ({
        marginLeft: drawerOpen === true ? Size.DRAWER_WIDTH_EXPANDED : drawerOpen === false ? Size.DRAWER_WIDTH_SHRINKED : 0,
        padding: isOnSearchPage ? '0 20px 20px 20px' : '20px',
        height: `calc(100% - ${Size.APPBAR_LOGO_WIDTH}px)`
    }));

interface UserCenterProps {
    children?: React.ReactNode;
    // Allow for mini drawer only, usually called outside of User Center
    miniOnly?: boolean;
}

export default function UserCenter({ children, miniOnly }: UserCenterProps) {
    const theme = useTheme();
    const { pathname } = useLocation();
    const { drawerOpen, shrinkDrawer, openDrawer, closeDrawer, loading: userInfoLoading } = useUserInfoContext();
    const [isHoveredOnHandle, setIsHoveredOnHandle] = useState(false);
    const message = useTranslatedMessage();
    const { navigateToMain } = useNavigate();
    const { user } = useUserInfoContext();
    const { logoutUser } = useUserAPI();
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";

    const isOnSearchPage = isSearchResultPath(pathname);
    const menuItems = USER_MENU_ITEMS.slice(0, -1);

    const handleDrawerChange = (event: MouseEvent | KeyboardEvent) => {
        // Prevent interaction if the event is not triggered by a left mouse click or key press
        if (event.type === 'keydown' && ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')) {
            return;
        }

        const action = drawerOpen ? shrinkDrawer : openDrawer;
        action();
    };

    const handleHover = (hover: boolean) => {
        setIsHoveredOnHandle(hover);
    };

    const IconComponent = isHoveredOnHandle
        ? theme.direction === 'rtl'
            ? (drawerOpen === true ? ChevronRightIcon : ChevronLeftIcon)
            : (drawerOpen === true ? ChevronLeftIcon : ChevronRightIcon)
        : DragHandleIcon;

    const closeMenuItem = {
        id: 'close',
        labelKey: 'Navigation.CloseSidebar',
        IconComponent: CloseIcon,
        onClick: () => closeDrawer()
    }

    const logoutMenuItem = {
        ...USER_MENU_ITEMS[USER_MENU_ITEMS.length - 1],
        onClick: () => {
            const email = user?.email ?? '';
            if (!email) return;
            logoutUser(email);
            navigateToMain({ industry: industryInCache });
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ProgressiveAppBar />
            <CssBaseline />
            {!userInfoLoading && drawerOpen !== undefined &&
                <Drawer variant="permanent" open={drawerOpen}>
                    <Tooltip title={drawerOpen === true ? message('Settings.Fold') : message('Settings.Expand')}>
                        {/* TODO: Create separate styled component for IconButton and make transition consistent with Drawer */}
                        <IconButton
                            style={{
                                transition: 'left 0.3s ease',
                                position: 'fixed',
                                top: '50%', // Middle of the screen
                                left: drawerOpen ? (Size.DRAWER_WIDTH_EXPANDED - 30) : (Size.DRAWER_WIDTH_SHRINKED - 28),   // Right edge of the screen
                                transform: `translateY(-50%) ${isHoveredOnHandle ? '' : 'rotate(90deg)'}`, // Adjust vertically to be in the middle
                                zIndex: 1201, // Ensure it's above the drawer
                                backgroundColor: 'transparent'
                            }}
                            disableRipple
                            onMouseDown={handleDrawerChange}
                            onMouseEnter={() => handleHover(true)}
                            onMouseLeave={() => handleHover(false)}
                        >
                            <IconComponent />
                        </IconButton>
                    </Tooltip>
                    <DrawerHeader sx={{ padding: 0, minHeight: `${Size.DRAWER_WIDTH_SHRINKED}px !important` }} className='drawerHeader'>
                        {drawerOpen === true ? <LogoBanner width={Size.DRAWER_LOGO_BANNER_WIDTH} /> : <LogoButton size={Size.DRAWER_WIDTH_SHRINKED} tooltipPosition='right' />}
                    </DrawerHeader>
                    <Divider />
                    <List sx={{ flex: 1 }}>
                        {menuItems.map((menuItem: MenuItemObj) => (
                            <DrawerMenuItem key={menuItem.id} menuItem={menuItem} drawerOpen={drawerOpen} />
                        ))}
                    </List>
                    <List>
                        {drawerOpen === false && <DrawerMenuItem menuItem={closeMenuItem} drawerOpen={drawerOpen} />}
                        <DrawerMenuItem menuItem={logoutMenuItem} drawerOpen={drawerOpen} />
                    </List>
                </Drawer>}
            <ContentTemplate drawerOpen={drawerOpen} isOnSearchPage={isOnSearchPage} className='content-template'>
                <MainView>
                    {children}
                </MainView>
            </ContentTemplate>
        </Box>
    );
}
