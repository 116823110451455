import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from 'react-google-recaptcha'
import { useUserAPI } from '../../hooks/use-user-api';
import { convertUserToSubmitRegistration } from '../../utils/parse-user-data';
import { updateFormError, validateFieldValue, validateRegisterFormFields } from '../../utils/validate-field-value';
import { useMessageContext } from '../../hooks/use-message-context';
import { Path, getLocaledAbsolutePath } from '../../constants';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { type UserForRegistration, type FormError, FormType } from '../../types';
import PasswordField from './PasswordField';
import EmailField from './EmailField';
import LoginLink from './LoginLink';
import ForgotPasswordLink from './ForgotPasswordLink';
import FormTextField from '../FormTextField';
import AuthPage from './AuthPage';
import { TextLink } from '../Link';
import LoadingButton from '../LoadingButton';
import { useIntlContext } from '../../hooks/use-intl-context';
import PhoneField from '../PhoneField';
import CountrySelect from '../CountrySelect';
import { CountryCode } from 'libphonenumber-js';
import { CountryType } from '../../constants/country';
import { useSearchParams } from 'react-router-dom';

export default function RegisterForm() {
    const { registerUser, activateUser, validateCaptcha } = useUserAPI();
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);
    const [captchaValue, setCaptchaValue] = useState<string | null | undefined>(undefined);
    const [formError, setFormError] = useState<FormError>({});
    const { showToastMessage } = useMessageContext();
    const { locale } = useIntlContext();
    const [selectedCountry, setSelectedCountry] = useState<CountryCode | null>(null);
    const message = useTranslatedMessage();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email') ?? "";
    // TODO: Correctly parse isActivate
    const isActivate = Boolean(searchParams.get('isActivate'));
    const orderNo = searchParams.get('orderNo') ?? "";
    const orderId = +(searchParams.get('orderId') ?? "");

    const isHumanVerified = Boolean(captchaValue);

    const onCountryChange = (country: CountryType | null) => {
        setSelectedCountry(country?.code as any);
        const status = validateFieldValue({ name: "country", value: country?.code ?? '', formType: FormType.REGISTRATION, message });
        setFormError((currFormError) => updateFormError(currFormError, "country", status.message));
    }

    const handleAgreementCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAgreementChecked(event.target.checked);
    };

    const handleOnRecaptchaChange = async (token: string | null) => {
        setCaptchaValue(token);
        const isCaptchaValidated = await validateCaptcha(token ?? "");
        const status = validateFieldValue({ name: "isHuman", value: isCaptchaValidated, formType: FormType.REGISTRATION, message });
        setFormError((currFormError) => updateFormError(currFormError, "isHuman", status.message));
    }

    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const status = validateFieldValue({ name, value, formType: FormType.REGISTRATION, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message))
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const user: UserForRegistration = {
            email: isActivate ? email : (formData.get('email') || '').toString(),
            password: (formData.get('password') || '').toString(),
            name: (formData.get('name') || '').toString(),
            mobilephone: (formData.get('mobilephone') || '').toString(),
            agreementChecked: formData.get('agreementChecked') === 'true',
            isHuman: Boolean(captchaValue),
            country: selectedCountry ?? '',
            locale,
            // by default, register as free user
            usertype: 0,
            dicttypeids: ''
        }

        const currFormError = validateRegisterFormFields(user, message);
        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            showToastMessage({ message: message('Form.Msg.PlsChangeSignUpInfo'), type: 'warning' });
            return;
        }

        if (isActivate) {
            activateUser(convertUserToSubmitRegistration(user), { orderId, orderNo }, setFormSubmitting);
        } else {
            registerUser(convertUserToSubmitRegistration(user), setFormSubmitting);
        }
    };

    const agreementCheckErrMsg = formError["agreementChecked"] || '';
    const isHumanVerifiedErrMsg = formError["isHuman"] || '';

    return (
        <AuthPage title={message(isActivate ? 'Form.Auth.SignUpAndActivate' : 'Form.Field.UserSignUp')}>
            <Box component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate sx={{ mt: 1 }}>
                {
                    email
                        ? <EmailField readonly initialValue={email} />
                        : <EmailField formError={formError} />
                }
                <PasswordField formError={formError} />
                <FormTextField
                    name="name"
                    label={message('Form.Profile.Name')}
                    autoComplete='name'
                    formError={formError}
                    required
                />
                <CountrySelect sx={{ mt: 1 }} required onChange={onCountryChange} formError={formError} />
                <PhoneField
                    name='mobilephone'
                    formError={formError}
                    country={selectedCountry}
                    initialCountry={selectedCountry}
                    required
                />

                {/* TODO: Need to implement verification code field for mobile phone
                <FormTextField
                    name="verification"
                    label="验证码"
                /> */}
                <Box sx={{ mt: 1.2, mb: 1.1 }}>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY ?? ""}
                        onChange={handleOnRecaptchaChange}
                        theme='dark'
                        hl={locale}
                    />
                </Box>
                <FormControlLabel
                    control={<Checkbox value={isHumanVerified}
                        checked={isHumanVerified}
                        name="isHuman"
                        color="primary" />}
                    label={''}
                    sx={{ display: 'none' }}
                />
                {isHumanVerifiedErrMsg ? (<FormHelperText error >{isHumanVerifiedErrMsg}</FormHelperText>) : null}
                <FormControlLabel
                    control={<Checkbox value={isAgreementChecked}
                        checked={isAgreementChecked}
                        name="agreementChecked"
                        onChange={handleAgreementCheckboxChange}
                        color="primary" />}
                    label={
                        <div>
                            {message('Form.Field.ReadAndAgree')}
                            <TextLink to={getLocaledAbsolutePath(Path.TERMS_AND_CONDITIONS, locale)}>{message('Form.Field.UserAgreement')}</TextLink>
                        </div>
                    }
                />
                {agreementCheckErrMsg ? (<FormHelperText error >{agreementCheckErrMsg}</FormHelperText>) : null}
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={Object.keys(formError).length > 0 || formSubmitting}
                    loading={formSubmitting}
                >
                    {message(isActivate ? 'Form.Auth.SignUpAndActivate' : 'Form.Auth.SignUp')}
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <LoginLink message={message} locale={locale} />
                    </Grid>
                    <Grid item>
                        <ForgotPasswordLink message={message} locale={locale} />
                    </Grid>
                </Grid>
            </Box>
        </AuthPage>
    );
}