import { getIndustryTitleById, getOrderStatusLabelKeyByState, getSubscriptionDurationNameByMonthNum, getSubscriptionLabelKeyById } from "../constants";
import { TranslationMessage } from "../hooks/use-translated-message";
import { DictOrderFull, DictOrderWithUserInfo } from "../types";
import { getDollarAmountLabel } from "./calculate-price";

export const parseOrderData = (orderData: any, message: TranslationMessage, locale: string): DictOrderFull => {
    const order: DictOrderFull = {} as DictOrderFull;
    if (orderData === null) return order;

    if (Boolean(orderData['activatetime'])) {
        order.activateTime = new Date(orderData['activatetime'] as string);
    }
    order.activeMonthNum = (orderData['activemonth'] as number).toString();
    order.activeMonth = message(getSubscriptionDurationNameByMonthNum(+order.activeMonthNum));
    order.baseCharge = orderData['base_charge'] as number;
    order.billingAddressLine1 = orderData['billing_address_line_1'] as string;
    order.billingAddressLine2 = orderData['billing_address_line_2'] as string;
    order.billingAddressLine3 = orderData['billing_address_line_3'] as string;
    order.billingAddressDistrict = orderData['billing_address_district'] as string;
    order.billingAddressCity = orderData['billing_address_city'] as string;
    order.billingAddressState = orderData['billing_address_state'] as string;
    order.billingAddressCountry = orderData['billing_address_country'] as string;
    order.billingAddressZipcode = orderData['billing_address_zipcode'] as string;
    order.createTime = new Date(orderData['createtime'] as string);
    order.currencyCode = orderData['currencycode'] as number;
    order.dictTypeIds = (orderData['dicttypes'] as string)
        .split(',');
    order.dictTypes = order.dictTypeIds
        .map((id) => getIndustryTitleById(+id, locale));
    if (Boolean(orderData['expiretime'])) {
        order.expireTime = new Date(orderData['expiretime'] as string);
    }
    order.invoiceId = orderData['invoice_id'] as string;
    order.invoiceTitle = orderData['invoice_title'] as string;
    order.isAutoRenew = Boolean(orderData['is_autorenew'] as number);
    order.isTrial = Boolean(orderData['is_trial'] as number);
    order.orderId = orderData['dictorderid'] as number;
    order.orderNo = orderData['dictorderno'] as string;
    order.orderStateId = orderData['dictorderstate'] as number;
    order.orderState = message(getOrderStatusLabelKeyByState(orderData['dictorderstate'] as number));
    if (Boolean(orderData['paytime'])) {
        order.payTime = new Date(orderData['paytime'] as string);
    }
    order.productId = (orderData['productid'] as number).toString();
    order.subscriptionTypeId = (orderData['subscription_type'] as number).toString();
    order.subscriptionType = message(getSubscriptionLabelKeyById(order.subscriptionTypeId));
    order.taxCharge = orderData['tax_charge'] as number;
    order.totalCharge = orderData['total_charge'] as number;
    order.paidAmount = orderData['paid_amount'] as number;
    order.userEmail = orderData['useremail'] as string;
    order.userName = orderData['username'] as string;
    order.userId = orderData['dictuserid'] as number;
    order.userPhone = orderData['userphone'] as string;
    order.subscriptionId = orderData['subscription_id'] as string;
    order.refOrderId = orderData['refOrderId'] as number;
    order.priceLabel = getDollarAmountLabel(orderData['total_charge'] as number, orderData['currencycode'] as number);
    const validPeriod = `${order.activateTime?.toLocaleDateString()} - ${order.expireTime?.toLocaleDateString()} (${order.activeMonth})`;
    order.validPeriod = Boolean(orderData['activatetime']) ? validPeriod : order.activeMonth;
    order.piid = orderData['piId'] as string;
    order.pmid = orderData['pmId'] as string;

    return order;
}

export const parseOrdersData = (ordersData: any[], message: TranslationMessage, locale: string): DictOrderFull[] => {
    return ordersData.map((orderData) => parseOrderData(orderData, message, locale));
}

export const mapOrderInfoToPayload = (orderInfo: DictOrderFull | DictOrderWithUserInfo) => {
    return ({
        dictuserid: orderInfo.userId,
        dicttypes: orderInfo.dictTypeIds.join(","),
        productid: orderInfo.productId,
        subscription_type: orderInfo.subscriptionTypeId,
        activemonth: orderInfo.activeMonthNum,
        base_charge: orderInfo.baseCharge,
        tax_charge: orderInfo.taxCharge,
        total_charge: orderInfo.totalCharge,
        currencycode: orderInfo.currencyCode,
        invoice_title: orderInfo.invoiceTitle,
        billing_address_line_1: orderInfo.billingAddressLine1,
        billing_address_line_2: orderInfo.billingAddressLine2,
        billing_address_line_3: orderInfo.billingAddressLine3,
        billing_address_district: orderInfo.billingAddressDistrict,
        billing_address_city: orderInfo.billingAddressCity,
        billing_address_state: orderInfo.billingAddressState,
        billing_address_country: orderInfo.billingAddressCountry,
        billing_address_zipcode: orderInfo.billingAddressZipcode,
        useremail: orderInfo.userEmail,
        username: orderInfo.userName,
        userphone: orderInfo.userPhone,
        is_trial: +orderInfo.isTrial,
        is_autorenew: +orderInfo.isAutoRenew,
        locale: orderInfo.locale,
        isRenew: orderInfo.isRenew,
        refOrderId: orderInfo.refOrderId
    });
}

// Used as a payload of payment intent
export const mapOrderFullToPayload = (orderInfo: DictOrderFull) => {
    return ({
        ...mapOrderInfoToPayload(orderInfo),
        dictorderid: orderInfo.orderId,
        dictorderno: orderInfo.orderNo,
        createtime: orderInfo.createTime
    });
}

export const groupRefOrders = (orders: DictOrderFull[]) => {
    const mainOrders: DictOrderFull[] = [];
    const refOrdersMap = new Map<number, DictOrderFull[]>();
    orders.forEach((order) => {
        if (!order.refOrderId) {
            mainOrders.push(order);
        } else {
            const refOrders = refOrdersMap.get(order.refOrderId) ?? [];
            refOrders.push(order);
            refOrdersMap.set(order.refOrderId, refOrders);
        }
    });

    mainOrders.forEach((order, idx) => {
        if (refOrdersMap.has(order.orderId)) {
            const refOrders = refOrdersMap.get(order.orderId) ?? [];
            refOrders.sort((refOrder1: DictOrderFull, refOrder2: DictOrderFull) => {
                const expireTime1 = refOrder1.expireTime;
                const expireTime2 = refOrder2.expireTime;
                if (expireTime1 === undefined) return expireTime2 === undefined ? -1 : 1;
                if (expireTime2 === undefined) return expireTime1 === undefined ? 1 : -1;

                return expireTime1 < expireTime2 ? -1 : 1
            })
            mainOrders[idx].refOrders = refOrders;
            mainOrders[idx].latestExpireDate = refOrders[refOrders.length - 1].expireTime;
        }
    })

    return mainOrders;
}
