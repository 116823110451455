import axios, { AxiosError } from 'axios';
import { DICT_SERVER_URL, DICT_SERVER_PRO_URL } from '../constants';
import { sanitizeSearchWord, validateSearchWord } from '../utils/sanitize-search-word';
import { useSearchDataContext } from './use-search-data-context';
import { useUserInfoContext } from './use-user-info-context';
import { useDebouncedCallback } from 'use-debounce';
import { parseStatus } from '../utils/parse-status';
import { useTranslatedMessage } from './use-translated-message';
import { SearchResult, StatusCode, UserStatus } from '../types';
import { useMessageContext } from './use-message-context';
import useNavigate from './use-navigate';

const useDictQuery = () => {
    const { industryId: tabIndustryId, setStatus, setLoading, setSearchResult } = useSearchDataContext();
    const { authToken, loading: userLoading, clearUserCache, userStatus } = useUserInfoContext();
    const message = useTranslatedMessage();
    const { openDialog, showToastMessage } = useMessageContext();
    const { navigateToProductProdict, navigateToLogin } = useNavigate();

    const queryDictApi = async (keyword: string, industryId?: string) => {
        setLoading(true);
        industryId = industryId ?? tabIndustryId;

        if (userLoading === true || userLoading === undefined) return;

        try {
            const response = await axios({
                method: 'post',
                url: Boolean(industryId) ? DICT_SERVER_PRO_URL : DICT_SERVER_URL,
                data: {
                    keyword,
                    industry: industryId === "all" ? "" : industryId
                },
                responseType: 'json',
                // This field makes sure cookies are sent
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${authToken?.jwt}`
                }
            });

            if (response.status === 429) {
                openDialog({ content: message(userStatus !== UserStatus.ONLINE ? 'Search.TooManyGuestRequests' : 'Search.TooManyRequests') });
                setTimeout(() => {
                    if (userStatus !== UserStatus.ONLINE) {
                        navigateToLogin();
                    } else {
                        navigateToProductProdict();
                    }
                }, 3000);

                return;
            }

            const { result, status } = await response.data;

            setSearchResult(result as SearchResult);
            setStatus(parseStatus(status.code, status.message));
        } catch (error) {
            const statusCode = await (error as AxiosError)?.response?.status;
            // Old Implementation
            // When user trying to get access to pro resource but unauthorized
            // 1. Refresh user information from server to downgrade gracefully
            // 2. Do not need to navigate user to main
            // 3. Logs out user and feed with search result as common user, handled by refreshUser internally

            // New Implementation
            // Redirect user to Login page
            // Requires all users getting access to pro trial to login
            if (statusCode === 401) {
                showToastMessage({ message: message(Boolean(industryId) ? 'Form.Msg.FailureAuthForPro' : 'Form.Msg.FailureAuth'), type: 'error' });
                navigateToLogin();
                clearUserCache();
                return;
            }

            if (statusCode === 429) {
                openDialog({ content: message(userStatus !== UserStatus.ONLINE ? 'Search.TooManyGuestRequests' : 'Search.TooManyRequests') });
                setTimeout(() => {
                    if (userStatus !== UserStatus.ONLINE) {
                        navigateToLogin();
                    } else {
                        navigateToProductProdict();
                    }
                }, 3000);
                return;
            }

            setSearchResult();
            // Render useful error prompt for users, rather than error message from server
            setStatus({
                status: StatusCode.FAIL,
                message: message('Form.Msg.NoReturn')
            });
        } finally {
            setLoading(false);
        }
    }

    const debouncedSearchDict = useDebouncedCallback((keyword: string, industryId?: string) => {
        const sanitizedKeyword = sanitizeSearchWord(keyword);
        const validation = validateSearchWord(sanitizedKeyword, keyword, message);
        setStatus({
            status: StatusCode.FAIL,
            message: validation.error
        });
        // If keyword fails validation in FE, return without triggering api call
        if (!validation.result) return;
        queryDictApi(sanitizedKeyword, industryId);
    }, 1000);

    return {
        searchDict: debouncedSearchDict
    }
}

export default useDictQuery;