import useNavigate from "./use-navigate";
import { UserStatus, type Address, type IPaymentCard, type IPaymentMethod, type UserCredential } from "../types"
import { useUserInfoContext } from "./use-user-info-context";
import { useMessageContext } from './use-message-context';
import { fetchData, fetchResponse, getErrorMessage, AxiosError } from "../utils/fetch-data";
import { parseUserData, parseLoginResult, parseUserSettingData } from '../utils/parse-user-data';
import { useTranslatedMessage } from "./use-translated-message";
import {
    USER_ADDRESS_URL,
    USER_PAYMENT_METHOD_URL,
    USER_LOGIN_URL,
    USER_LOGOUT_URL,
    USER_REGISTER_URL,
    USER_FORGOT_PASSWORD_URL,
    USER_RESET_PASSWORD_URL,
    USER_UPDATE_LOCALE_URL,
    USER_UPDATE_PROFILE_URL,
    USER_VALIDATE_CAPTCHA_URL,
    USER_SET_DEFAULT_PAYMENT_METHOD_URL,
    USER_DELETE_PAYMENT_METHOD_URL,
    USER_CURRENT_URL,
    USER_ACTIVATE_URL,
    LocalStorageKey
} from "../constants";
import { useIntl } from "react-intl";
import { parseAddressData } from "../utils/parse-address-data";
import { parsePaymentCardData, parsePaymentData } from '../utils/parse-payment-method-data';
import { useCallback } from "react";
import { getFromLocalStore } from "../utils/local-storage";

export const useUserAPI = () => {
    const { user, setUser, markLoginFail, rememberedEmail, setRememberedEmail, setAuthToken, setUserStatus, loading: userLoading, syncSetting, clearUserCache } = useUserInfoContext();
    const { showToastMessage, openDialog } = useMessageContext();
    const { navigateToLogin, navigateToMain } = useNavigate();
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const userId = user?.id;
    const userEmail = user?.email ?? "";
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";

    const loginUser = useCallback(async (userCredential: UserCredential, setLoading: (loading: boolean) => void) => {
        const { email, password, rememberMe } = userCredential;

        try {
            setLoading(true);
            const { result } = await fetchData(
                USER_LOGIN_URL,
                {
                    method: 'POST',
                    data: {
                        email,
                        password,
                        locale
                    }
                }
            );
            const { user: fetchedUser, authToken: newAuthToken } = parseLoginResult(result);
            setUser(fetchedUser);
            setUserStatus(UserStatus.ONLINE);
            setAuthToken(newAuthToken);
            showToastMessage({ message: message('Form.Msg.WelcomeSignIn', { name: fetchedUser.name ?? '' }), type: 'success' });
            setRememberedEmail(rememberMe ? fetchedUser.email : "");
            setTimeout(() => navigateToMain({ hardRefresh: true, industry: industryInCache }), 1000);
        } catch (e) {
            markLoginFail();
            showToastMessage({ message: message('Form.Msg.FailureSignIn'), type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [
        markLoginFail,
        message,
        locale,
        navigateToMain,
        showToastMessage,
        setRememberedEmail,
        setUser,
        setAuthToken,
        setUserStatus,
        industryInCache
    ]);

    const logoutUser = useCallback(async (email: string) => {
        try {
            await fetchData(
                USER_LOGOUT_URL,
                {
                    method: 'POST',
                    params: {
                        email
                    }
                }
            );
        } catch (e) {

        }

        // In case server response is not available, log out user in FE.
        clearUserCache();
        showToastMessage({ message: message('Form.Msg.SuccessLogout'), type: 'info' });
    }, [clearUserCache, showToastMessage, message])

    const registerUser = useCallback(async (userToSubmit: any, setLoading: (loading: boolean) => void) => {
        try {
            setLoading(true);
            const { data, status } = await fetchResponse(
                USER_REGISTER_URL,
                {
                    method: 'POST',
                    data: userToSubmit
                }
            );

            if (status?.code !== 200) {
                const errMsg = status.message ?? "";
                showToastMessage({ message: `${message('Form.Msg.FailureSignUp')}, ${errMsg}`, type: 'error' });
                return;
            }

            const { result } = data;
            const { user: fetchedUser, authToken: newAuthToken } = parseLoginResult(result);
            setUser(fetchedUser);
            setUserStatus(UserStatus.ONLINE);
            setAuthToken(newAuthToken);

            showToastMessage({ message: message('Form.Msg.WelcomeSignUp', { name: fetchedUser.name ?? '' }), type: 'success' });
            setTimeout(() => navigateToMain({ hardRefresh: true, industry: industryInCache }), 3000);
        } catch (e) {
            showToastMessage({ message: `${message('Form.Msg.FailureSignUp')}, ${getErrorMessage(e as AxiosError)}`, type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [message, navigateToMain, setUser, setAuthToken, setUserStatus, showToastMessage, industryInCache])

    const activateUser = useCallback(async (userToSubmit: any, orderInfo: { orderNo: string, orderId: number }, setLoading: (loading: boolean) => void) => {
        try {
            setLoading(true);
            const { data, status } = await fetchResponse(
                USER_ACTIVATE_URL,
                {
                    method: 'POST',
                    data: userToSubmit,
                    params: orderInfo
                }
            );

            if (status.code === 200 && Boolean(status.message)) {
                openDialog({
                    content: status.message ?? "", confirmButtonText: message('Form.Button.ReturnHome'), onConfirm: () => {
                        navigateToMain({ industry: industryInCache });
                    }
                });
            }

            if (!data?.result) {
                showToastMessage({ message: `${message('Form.Msg.FailureAccountActivation')}: ${status.message ?? ""} `, type: 'error' });
                return;
            }

            const { user: fetchedUser, authToken: newAuthToken } = parseLoginResult(data.result);
            setUser(fetchedUser);
            setUserStatus(UserStatus.ONLINE);
            setAuthToken(newAuthToken);

            showToastMessage({ message: message('Form.Msg.WelcomeSignUp', { name: fetchedUser.name ?? '' }), type: 'success' });
            setTimeout(() => navigateToMain({ hardRefresh: true }), 5000);
        } catch (e) {
            showToastMessage({ message: `${message('Form.Msg.FailureAccountActivation')}, ${getErrorMessage(e as AxiosError)}`, type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [message, navigateToMain, setUser, setAuthToken, setUserStatus, showToastMessage, openDialog, industryInCache])

    const forgotPassword = useCallback(async (email: string, setLoading: (loading: boolean) => void) => {
        try {
            setLoading(true);
            const responseMsg = await fetchData(
                USER_FORGOT_PASSWORD_URL,
                {
                    method: 'POST',
                    params: {
                        email
                    }
                }
            );

            openDialog({
                content: responseMsg, confirmButtonText: message('Form.Button.ReturnHome'), onConfirm: () => {
                    navigateToMain({ industry: industryInCache });
                }
            });
        } catch (e) {
            showToastMessage({ message: message('Form.Msg.FailureRecoverPass'), type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [message, navigateToMain, openDialog, showToastMessage, industryInCache])

    const refreshUser = useCallback(async (setLoading?: (loading: boolean) => void) => {
        // stop refresh if user has not logged in or refresh is happening
        if (userLoading !== false || !userId) return;

        try {
            setLoading?.(true);
            const response = await fetchData(
                USER_CURRENT_URL,
                {
                    method: 'GET',
                    params: { userId }
                }
            );
            // If current user is null from server, when it has non-null userId locally
            // logout the current user, and return
            if (!response?.result) {
                await logoutUser(userEmail);
                return;
            }
            const { user: userData, setting } = response.result;
            const fetchedUser = parseUserData(userData);
            setUser(fetchedUser);
            syncSetting(parseUserSettingData(setting));
        } catch (e) {
            console.log('error refreshing user', e);
        } finally {
            setLoading?.(false);
        }
    }, [userId, userLoading, setUser, syncSetting, logoutUser, userEmail])

    const resetPassword = useCallback(async (userCredential: UserCredential, setLoading: (loading: boolean) => void) => {
        const { token, password, oldPassword, email } = userCredential;

        try {
            setLoading(true);
            const response = await fetchResponse(
                USER_RESET_PASSWORD_URL,
                {
                    method: 'POST',
                    params: {
                        token,
                        password,
                        oldPassword,
                        email
                    }
                }
            );

            if (response.status.code === 200) {
                showToastMessage({ message: message('Form.Msg.SuccessResetPassword'), type: 'success' });
                setTimeout(() => {
                    navigateToLogin();
                    clearUserCache();
                }, 3000);
            } else {
                showToastMessage({
                    message: `${message('Form.Msg.FailureResetPass')}: ${response.status.message}`,
                    type: 'error'
                });
            }

        } catch (e) {
            showToastMessage({
                message: `${message('Form.Msg.FailureResetPass')}: ${getErrorMessage(e as AxiosError)}`,
                type: 'error'
            });
        } finally {
            setLoading(false);
        }
    }, [message, navigateToLogin, showToastMessage, clearUserCache]);

    const updateProfile = useCallback(async (userToSubmit: any, setLoading: (loading: boolean) => void, callback?: () => void) => {
        try {
            setLoading(true);
            const { result: user } = await fetchData(
                USER_UPDATE_PROFILE_URL,
                {
                    method: 'POST',
                    data: userToSubmit
                },
                true
            );
            const fetchedUser = parseUserData(user);
            setUser(fetchedUser);

            showToastMessage({ message: message('Form.Msg.SuccessChangePersonalInfo'), type: 'success' });
            callback?.();
        } catch (e) {
            showToastMessage({ message: `${message('Form.Msg.FailureUpdateProfile')}, ${getErrorMessage(e as AxiosError)}`, type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [showToastMessage, message, setUser])

    const updateUserLocale = useCallback(async (userId: string, locale: string) => {
        if (!userId) return;

        try {
            await fetchData(
                USER_UPDATE_LOCALE_URL,
                {
                    method: 'POST',
                    params: {
                        userId,
                        locale
                    }
                },
                false
            );
        } catch (e) { }
    }, []);

    const validateCaptcha = useCallback(async (userToken: string) => {
        if (!userToken) return false;

        try {
            const response = await fetchData(
                USER_VALIDATE_CAPTCHA_URL,
                {
                    method: 'POST',
                    params: {
                        userToken
                    }
                }
            );

            return response;
        } catch (e) {
            return false;
        }
    }, [])

    const getUserAddresses = useCallback(async (userId?: string | null): Promise<Address[]> => {
        if (!Boolean(userId)) return [];
        try {
            const response = await fetchData(
                USER_ADDRESS_URL,
                {
                    method: 'GET',
                    params: {
                        userId
                    }
                }
            );

            const rawAddresses = await response.result ?? [];

            return rawAddresses.map((address: any) => parseAddressData(address));
        } catch (e) {
            return [];
        }
    }, []);

    const getDefaultAddress = useCallback(async (userId?: string | null): Promise<Address | null> => {
        if (!Boolean(userId)) return null;

        try {
            const response = await fetchData(
                USER_ADDRESS_URL,
                {
                    method: 'GET',
                    params: {
                        userId,
                        isDefault: 1
                    }
                }
            );

            const rawAddress = await response.result[0] ?? null;

            return parseAddressData(rawAddress);
        } catch (e) {
            return null;
        }
    }, []);

    const getUserPaymentMethods = useCallback(async (userId?: string | null): Promise<{ paymentMethod: IPaymentMethod[], paymentCard: IPaymentCard[] }> => {
        if (Boolean(userId)) {
            try {
                const response = await fetchData(
                    USER_PAYMENT_METHOD_URL,
                    {
                        method: 'GET',
                        params: {
                            userId
                        }
                    }
                );

                const result = await response.result ?? {};

                return ({
                    paymentMethod: (result.paymentMethod ?? []).map(parsePaymentData),
                    paymentCard: (result.paymentCard ?? []).map(parsePaymentCardData)
                });
            } catch (e) {
            }
        }
        return ({
            paymentMethod: [], paymentCard: []
        });
    }, []);

    const getDefaultPaymentMethod = useCallback(async (userId?: string | null): Promise<{ paymentMethod: IPaymentMethod | null, paymentCard: IPaymentCard | null }> => {
        if (Boolean(userId)) {
            try {
                const response = await fetchData(
                    USER_PAYMENT_METHOD_URL,
                    {
                        method: 'GET',
                        params: {
                            userId,
                            isDefault: 1
                        }
                    }
                );

                const rawResult = await response.result[0] ?? {};

                return {
                    paymentMethod: parsePaymentData(rawResult.paymentMethod),
                    paymentCard: parsePaymentCardData(rawResult.paymentCard)
                };
            } catch (e) {
            }
        }
        return ({
            paymentMethod: null, paymentCard: null
        });
    }, []);

    const setDefaultPaymentMethod = useCallback(async (stripePmId: string, userId: number, setLoading: (loading: boolean) => void, callback: () => void) => {
        try {
            setLoading(true);
            await fetchData(
                USER_SET_DEFAULT_PAYMENT_METHOD_URL,
                {
                    method: 'POST',
                    params: {
                        stripePmId,
                        userId
                    }
                },
                true
            );
            showToastMessage({ message: message('Form.Msg.SuccessSetDefaultPaymentMethod'), type: 'success' });
            callback();
        } catch (e) {
            showToastMessage({ message: message('Form.Msg.FailureSetDefaultPaymentMethod'), type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [message, showToastMessage]);

    const deletePaymentMethod = useCallback(async (stripePmIds: string[], userId: number, setLoading: (loading: boolean) => void, callback: () => void) => {
        try {
            setLoading(true);
            await fetchData(
                USER_DELETE_PAYMENT_METHOD_URL,
                {
                    method: 'POST',
                    params: {
                        userId
                    },
                    data: stripePmIds
                },
                true
            );
            showToastMessage({ message: message('Form.Msg.SuccessDeletePaymentMethod'), type: 'success' });
            callback();
        } catch (e) {
            showToastMessage({ message: message('Form.Msg.FailureDeletePaymentMethod'), type: 'error' });
        } finally {
            setLoading(false);
        }
    }, [showToastMessage, message]);

    return ({
        rememberMe: Boolean(rememberedEmail),
        loginUser,
        logoutUser,
        registerUser,
        activateUser,
        forgotPassword,
        getUserAddresses,
        getDefaultAddress,
        getUserPaymentMethods,
        getDefaultPaymentMethod,
        resetPassword,
        updateProfile,
        updateUserLocale,
        validateCaptcha,
        setDefaultPaymentMethod,
        deletePaymentMethod,
        refreshUser
    })
}